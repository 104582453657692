import React, { useEffect, useState } from "react";
import { AllProgPostsFotr } from "../../data/AllProgPostsFotr";

const ProgPostFotr = () => {
  const [blogPost, setBlogPost] = useState({});
  const [activeSection, setActiveSection] = useState("background");

  useEffect(() => {
    document.title = `${blogPost.name} | Carole Kinoti Brands`;
  }, [blogPost.name]);

  useEffect(() => {
    try {
      const pathname = window.location.pathname;
      const parts = pathname.split("/"); // Split the pathname by "/"
      const formattedName = parts[2]?.replaceAll('-', ' ').toLowerCase(); // Access the third part and format it
      const filteredPost = AllProgPostsFotr.find((post) =>
        post.name.toLowerCase() === formattedName
      );
      console.log("Pathname:", pathname);
      console.log("Formatted Name:", formattedName);
      console.log("Filtered Post:", filteredPost);
      setBlogPost(filteredPost || {});
    } catch (error) {
      console.error("Error fetching blog post:", error);
    }
  }, []);


  return (
    <main className="ablog-post">
      {/* Blog buttons and content container */}
      <div className="ablog-container">
        {/* Blog content */}
        <section className="ablog-content">
          <h1>{blogPost.name}</h1>
          <img src={blogPost.img} alt="" aria-hidden="true" />

          {activeSection === "background" && (
            <section className="ablog-content-1">
              <h1>Background</h1>
              <p>{blogPost.background}</p>
            </section>
          )}
          {activeSection === "goal" && (
            <section>
              <h1>Goal</h1>
              <p>{blogPost.goal}</p>
            </section>
          )}
          {activeSection === "admission" && (
            <section>
              <h1>Admission</h1>
              <p>{blogPost.admission}</p>
            </section>
          )}
          <div className="mblog-button-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <button
              onClick={() => setActiveSection("background")}
              className="ablog-button-left"
            >
              Background
            </button>
            <button onClick={() => setActiveSection("goal")} className="ablog-button-left">
              Goal
            </button>
            
          </div>
        </section>
      </div>
    </main>
  );
};

export default ProgPostFotr;
