
// Prog.js
import React, { useEffect, useState } from 'react';
import { getDocs, collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { motion } from "framer-motion";
import BlogGrid from "./ProgGrid"; // Make sure this matches the file name

const Prog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    document.title = "Blog | Carole Kinoti Brand";
    const fetchBlogPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Carole-programs"));
        const posts = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog posts: ", error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <main className="prog">
        <article className="prog-section">
          <h2>Our Programs</h2>
          <p className="prog-intro">
            Empowering through Knowledge: Our diverse range of programs are meticulously curated 
            to cater to varying needs, driving innovation, creativity, and holistic growth. 
            Whether you're a budding entrepreneur from marginalized communities or an enthusiastic 
            learner, our programs bridge the gap between ambition and realization, offering unparalleled 
            mentorship, resources, and opportunities to shine in the digital age. Join us, and let's co-create 
            a brighter, more inclusive future!
          </p>
          <section className="prog-grid">
            {blogPosts.map((blogPost, index) => (
              <BlogGrid key={blogPost.id} blogPost={blogPost} />
            ))}
          </section>
        </article>
      </main>
    </motion.div>
  );
};

export default Prog;
