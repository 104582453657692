import Img1 from "../assets/images/blog/post-1.jpeg";
import Img2 from "../assets/images/blog/post-2.jpeg";
import Img3 from "../assets/images/blog/post-3.jpeg";
import Img4 from "../assets/images/blog/post-4.jpeg";
import Img5 from "../assets/images/blog/post-5.jpeg";
import Img6 from "../assets/images/blog/post-6.png";
import Img7 from "../assets/images/FOTR-prog/FOTR Conversations/fotr-conv.jpeg";
import Img8 from "../assets/images/FOTR-prog/FOTR Golf Series Kisumu County with DTB-20230918T111620Z-001/FOTR Golf Series Kisumu County with DTB/golf.jpeg";
import Img9 from "../assets/images/FOTR-prog/caravan/Caravan.png";
import Img10 from "../assets/images/FOTR-prog/caravan/Fotr.png";

export const AllProgPostsFotr = [
  {
    img: Img6,
    name: "The Gifted Runway Bootcamp",
    intro:
      "The Gifted Runway Bootcamp, presented in partnership with Carole Kinoti Brands, nurtures emerging fashion talents by providing hands-on training, mentorship, and exposure to the fashion industry.!",
    //date: "May 3, 2023",
    //author: "Tom Wilson",
    id: 6,
    background: `
    THE GIFTED RUNWAY BOOTCAMP emerged to address a profound void in the fashion and design sector. Historically, the industry has been characterized by a singular outlook, frequently marginalizing the rich and diverse creativity present worldwide. Acknowledging that talent and ingenuity are not bound by race, gender, or nationality, this bootcamp was conceived to amplify the voices and creations of individuals hailing from various cultural backgrounds.

In an era where inclusivity and diversity are celebrated, the bootcamp stands as a beacon of change. It serves as a bridge, connecting emerging designers from all walks of life and providing them with the mentorship, exposure, and resources needed to thrive in the fashion world. By fostering a supportive and inclusive environment, it empowers participants to break through barriers and embrace their unique perspectives.

The impact of THE GIFTED RUNWAY BOOTCAMP extends beyond the runway. It's about breaking stereotypes, challenging industry norms, and opening doors for fresh talent. Through this initiative, a new generation of fashion leaders is being nurtured, breaking down the barriers that have limited the industry's potential for far too long. As these gifted designers take their place on the global stage, they inspire change and drive fashion towards a more inclusive and vibrant future.
    `,
    goal:`Our primary objective is to celebrate and promote diversity in design. By the end of the bootcamp, participants should:

    1. Have developed a robust portfolio reflecting their unique design perspective.
    2. Have a deeper understanding of global design trends and how their unique voice fits into the broader landscape.
    3. Be equipped with the skills and network to launch or advance their careers in the design and fashion industry.
    4. Foster collaborations and build a community that champions diversity in all facets of the industry.`,
    admission:`Portfolio Submission: Applicants are required to submit a portfolio showcasing their previous work. This should include at least 5 design pieces that best represent their style and perspective.

    1. Personal Statement: A written statement (not exceeding 500 words) detailing the applicant's passion for design, their understanding of diversity in the fashion industry, and what they hope to achieve from the bootcamp.
    
    2. Age Limit: Applicants should be between the ages of 18 to 35. Exceptions can be made on a case-by-case basis for outstanding talent.
    
    3. Recommendation Letters: Two recommendation letters from professionals in the design or fashion industry, or from academic advisors if the applicant is a recent graduate.
    
    4. Interview: Shortlisted candidates will be invited for a virtual or in-person interview with the admission panel.
    
    Note: While we prioritize promoting diversity, admission is based on talent and potential, and all passionate individuals, regardless of their background, are encouraged to apply.`
  },
  {
    img: Img7,
    name: "FOTR Conversations",
    intro:
      "Mavazi Sister to Sister Mashinani Mentorship, in partnership with Carole Kinoti Brands, fosters mentorship and skill-sharing among women in rural communities, promoting empowerment and entrepreneurship.",
    //date: "April 11, 2023",
    //author: "Kerry Smith",
    id: 7,
    background: `
    FOTR SOKOS AND CONVERSATIONS epitomizes a profound reverence for the rich tapestry of Kenya's cultural heritage and an unwavering commitment to celebrating the nation's distinctive fashion narrative. This initiative stands as a testament to the power of collaboration and the fusion of creative energies. Influential figures such as Connie Aluoch, Mwenesi Musalia, Deborah Sanaipei, Savara of Sauti Sol, and Gina-Din Kariuki have played pivotal roles in amplifying our brand's message. Their wide-reaching influence has served as a conduit for carrying the essence of our brand to audiences near and far.

In a digital age, we've harnessed the potential of social media and various e-commerce platforms to craft an immersive experience that defies geographical confines. Through these channels, we've not only showcased the vibrant and diverse facets of Kenyan culture but also transported this cultural mosaic onto a global stage. FOTR SOKOS AND CONVERSATIONS isn't merely an endeavor in fashion; it's a movement that embodies the spirit of unity, diversity, and creativity, propelling Kenyan culture into the global spotlight.
    
    `,
    goal:`Our primary goal is to foster cultural exchange and promote the essence of the Kenyan brand. By collaborating with influential figures and partnering with organizations like Simba Corp Motors, Kenya Tourism Board (KTB), and Karen Country Club, we aim to create memorable moments that celebrate the spirit of Kenya. Through events like the annual Marvellous Mums, which honors the bond between mothers and daughters, we strive to create a sense of belonging and appreciation for Kenyan fashion, art, and heritage.`,
    admission:`To participate in FOTR SOKOS AND CONVERSATIONS, all you need is an enthusiasm for Kenyan culture and a passion for fashion. Whether you're a fashion enthusiast, an artist, or simply someone who appreciates the beauty of Kenya, our platform welcomes individuals from all walks of life. Stay connected with us on social media, engage in our events, and be part of our journey as we celebrate the diverse tapestry of Kenyan fashion and culture.`
  },
  {
    img: Img8,
    name: "FOTR Sports Series",
    intro:
      "The Annual Fashion on the Road Charity Sports Event celebrates creativity and inclusivity in Golf, Polo, Cricket, and fashion, honoring Mavazi Elevate Programs graduates.",
    //date: "April 2, 2023",
    //author: "Kira Wilson",
    //id: 8,
    background: `
    The Fashion on the Road Cultural Charity Sports Tournament has a storied legacy of uniting not only sports enthusiasts but also prominent government officials and influential figures from diverse sports communities. This prestigious event has played host to luminaries from the worlds of Golf, Polo, Cricket, and the fashion industry, creating an unparalleled platform for connections and collaborations. 
    
    Distinguished guests who have graced the tournament include Cabinet Secretary Amb. Dr. Amina Mohammed, representing the Ministry of Sports, Culture, and Heritage, as well as Chief Cabinet Secretary Hon. Betty C. Maina, EGH, from the Ministry of Industry, Trade, and Enterprise Development. Moreover, the tournament has seen the participation of esteemed High Commissioners from countries like Australia, Rwanda, and Tanzania, along with the Honorable Mr. Luke Williams and UN ambassadors. Key business leaders, including Linus Wang'ombe Gitahi, CEO of Tropikal Brands Afrika, and Stephen Gitau, CEO of Amref, have also contributed to the tournament's remarkable legacy, making it an event that transcends borders and industries.
    `,
    goal:`The primary goal of the Annual Inaugural Fashion on the Road Cultural Charity Golf Tournament is to create a platform where art, fashion, and culture converge to drive positive change. By celebrating creativity and showcasing fashion that leaves no one behind, this event aims to foster inclusivity, empowerment, and sustainability in the fashion industry. It provides a unique opportunity for talented individuals, including those from marginalized backgrounds such as prisoners and people with disabilities, to participate as models and showcase their potential.`,
    admission:``
  },
  {
    img: Img9,
    name: "FOTR Caravan",
    intro:
      "The Fashion on the Road (FOTR) Caravan is a pioneering initiative committed to promoting sustainability and positive social impact within the fashion industry.",
    //date: "March 18, 2023",
    //author: "George Denson",
    id: 9,
    background: `
    The heart of the FOTR Caravan beats with a commitment to sustainability and environmental responsibility. Our mission centers on the promotion of reuse and recycling, specifically targeting used clothing and textile waste. To bring this mission to life, we collaborate closely with various organizations, strategically placing collection bins in numerous locations across the country. These unassuming bins serve as essential collection points for donated clothes and textile trimmings, effectively transforming what might have been considered waste into valuable resources for our communities.

FOTR Caravan's impact extends far beyond these collection bins; it's a holistic approach to fostering sustainability within the fashion sector. Through education and awareness initiatives, we aim to enlighten individuals about the significance of reducing textile waste and embracing a more eco-conscious approach to fashion. By facilitating the repurposing and upcycling of textiles, we not only divert items from landfills but also contribute to the circular economy, where every thread and fabric scrap finds a new purpose. At the heart of the FOTR Caravan is a vision of a more sustainable and responsible fashion industry, one stitch at a time.
    `,
    goal:`Since its inception, the FOTR Caravan has made significant strides in collecting and repurposing clothing and fabric trimmings. In 2019 alone, our caravan collected an impressive 3,000 kilograms of donated clothes and fabric trimmings. These resources were then distributed to benefit 200 street children in Mlango Kubwa, 300 young girls in Mount Elgon who were survivors of female genital mutilation (FGM), and children in Gertrudes Hospital. In 2021, our efforts continued, resulting in the collection of an additional 1,000 kilograms of donated clothes, which were thoughtfully distributed to Langata Women's Prison. Our goal remains to expand our reach and impact further, ensuring the sustainability and inclusivity of our initiatives.`,
    admission:`The FOTR Caravan is an inclusive program, and admission is open to all who wish to contribute to our mission. We welcome individuals, organizations, and partners who share our passion for sustainability, community empowerment, and social responsibility. Join hands with us in making a positive change through the power of fashion.`
  },
  {
    img: Img10,
    name: "FOTR Duka",
    intro:
      "Fashion on the Road Duka/Exhibition Markets Concept Note 2023",
   // date: "March 7, 2023",
   // author: "Alex Lee",
    id: 10,
   // content: `one,two`,
    background: `
    FOTR Duka is more than just an e-commerce platform; it's a vibrant and collaborative fashion marketplace born from a desire to nurture creativity and provide opportunities for fashion enthusiasts, designers, and creators. Conceived by the visionary fashion designer Carole Kinoti, FOTR Duka embodies her passion for empowering emerging talents and fostering a sense of community within the fashion world.

Carole Kinoti's own fashion brand has earned acclaim for its distinctive designs and unwavering commitment to quality. However, her journey also revealed a significant challenge faced by many talented individuals – the struggle to find the right platform for showcasing and marketing their creations. This realization served as the driving force behind FOTR Duka, a space where both established and aspiring designers can unite, collaborate, and expand their presence in the dynamic landscape of the fashion industry.

At FOTR Duka, the focus extends beyond commerce; it's about building connections, sharing experiences, and celebrating the diversity of creative expression. By providing a supportive environment for designers and creators to flourish, FOTR Duka is not just a marketplace; it's a thriving community where fashion dreams come to life.

    `,
    goal: `Goal:

    The primary goal of FOTR Duka is to create a thriving and inclusive fashion community that empowers designers and creators to:
    
    Showcase Their Creativity: FOTR Duka provides a platform for designers to exhibit their fashion collections, accessories, and unique creations, allowing them to express their creativity and reach a wider audience.
    
    Boost Sales: By leveraging the FOTR Duka platform, collaborators can increase their sales potential and gain exposure to a diverse customer base. The platform's e-commerce capabilities simplify the selling process and facilitate secure transactions.
    
    Foster Collaboration: Collaboration is at the core of FOTR Duka. The platform encourages designers to collaborate with Carole Kinoti and each other, facilitating the exchange of ideas, techniques, and expertise to create innovative fashion collections.
    
    Access Marketing Support: FOTR Duka offers marketing and promotional support to its collaborators, helping them gain visibility through social media, online advertising, and partnerships. This support extends to product launches and events.
    
    Build a Community: Beyond business transactions, FOTR Duka aims to build a community of fashion enthusiasts, fostering relationships, mentorship opportunities, and a sense of belonging among its members.
    
    Celebrate Diversity: The platform celebrates diversity in fashion, encouraging designers of all backgrounds, styles, and aesthetics to join and contribute to the vibrant tapestry of the fashion world.
    `,
    admission: `Collaborator Registration: Fashion designers and creators who are interested in joining FOTR Duka begin by registering as collaborators on the platform. Registration is a straightforward process, requiring basic information and the submission of sample product images.

    Profile Creation: Upon registration, collaborators create personalized profiles that highlight their brand identity, style, and product offerings. They can also share their brand story, inspirations, and aspirations, allowing customers to connect with them on a deeper level.
    
    Product Showcase: Collaborators upload high-quality images and descriptions of their fashion products to the FOTR Duka platform. Each product is presented in a visually appealing manner to attract potential customers. Collaborators have the flexibility to manage their inventory, prices, and product details.
    `,
  },
];


