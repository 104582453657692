import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './eventDetailsPage.css';
import { useAuth } from '../../../contexts/AuthContext'; // Adjust the import path as necessary
// Add the modal component
import LoginModal from '../../../components/LoginModal';

const db = getFirestore();

const EventDetailsPage = () => {
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [ticketCounts, setTicketCounts] = useState({});
  
  // Replace local isLoggedIn and isLoginModalActive with the context
  const {
    isLoggedIn,
    showLoginModal,
    isLoginModalActive,
    hideLoginModal,
    setValidLogin,
    getUser,
    validLogin
  } = useAuth();

  // Add a state to control the visibility of the login modal specifically for this page if it's not provided by useAuth
  const [loginModalWindow, setLoginModalWindow] = useState(false);

  useEffect(() => {
    const fetchEventDetails = async () => {
      const eventDoc = doc(db, 'events', eventId);
      const eventSnapshot = await getDoc(eventDoc);

      if (eventSnapshot.exists()) {
        setEventDetails({
          id: eventSnapshot.id,
          ...eventSnapshot.data(),
        });
        // Initialize ticketCounts with 0 for each ticket type
        const initialCounts = {};
        eventSnapshot.data().tickets.forEach(ticket => {
          initialCounts[ticket.name] = 0;
        });
        setTicketCounts(initialCounts);
      } else {
        console.error("Event not found!");
      }
    };

    fetchEventDetails();
  }, [eventId]);

  

  const handleQuickBuy = () => {
    if (!isLoggedIn) {
      // Now using showLoginModal from AuthContext or local state
      showLoginModal(); // Make sure this method sets the loginModalWindow to true
      setLoginModalWindow(true); // If `showLoginModal` does not exist, use local state to show the modal
    } else {
      // If logged in, proceed with the quick buy process
      // Implement the purchase logic here
    }
  };



  const incrementTicket = (ticketName) => {
    setTicketCounts(prevCounts => ({
      ...prevCounts,
      [ticketName]: prevCounts[ticketName] + 1
    }));
  }

  const decrementTicket = (ticketName) => {
    setTicketCounts(prevCounts => ({
      ...prevCounts,
      [ticketName]: Math.max(0, prevCounts[ticketName] - 1) // Ensure ticket count doesn't go below 0
    }));
  }

   // Add a function to calculate the total cost
   const getTotalCost = () => {
    return eventDetails.tickets.reduce((total, ticket) => {
      const price = typeof ticket.price === 'string' ? parseFloat(ticket.price) : ticket.price;
      return total + (ticketCounts[ticket.name] || 0) * price;
    }, 0);
  };
  


  if (!eventDetails) return <p>Loading...</p>;

  return (
    <div className="event-details">
      <div className="event-header">
        <img className="event-image" src={eventDetails.imageUrl} alt={eventDetails.name} />
        <div className="event-info">
          <h1>{eventDetails.name}</h1>
          <p className="event-time">{eventDetails.date} {eventDetails.time}</p>
          <p className="event-location">{eventDetails.location}</p>
          <p className="event-organizer">BY {eventDetails.organizer}</p>
          <p className="event-inquiries">Inquiries: {eventDetails.inquiriesNumber}</p>
          
            {/* Title for the event description */}
            <h3>Event Description</h3>
          {/* Displaying the event description below inquiries */}
          <p className="event-description">{eventDetails.description}</p>

        </div>
      </div>

      <form className="tickets-section">
        <div className="tickets-available">
          <h3>Tickets Available</h3>
          {eventDetails.tickets && eventDetails.tickets.map(ticket => (
  <div key={ticket.name} className="ticket-item">
    <div className="ticket-info">
      <h4>{ticket.name}</h4>
      <p>{typeof ticket.price === 'string' ? parseFloat(ticket.price).toFixed(2) : ticket.price}</p>
      <p>{ticket.status}</p>
    </div>
    <div className="ticket-quantity">
    <button type="button" onClick={() => decrementTicket(ticket.name)}>-</button>
      <input type="number" value={ticketCounts[ticket.name] || 0} readOnly />
      <button type="button" onClick={() => incrementTicket(ticket.name)}>+</button>
    </div>
  </div>
))}
        </div>
        <div className="total-cost">
          <p>Total Cost: Kshs {getTotalCost().toFixed(2)}</p>
        </div>
        {/* Add additional form fields for user details here */}
        <button type="submit" className="proceed-to-pay">PROCEED TO PAY</button>
      </form>
      
      {isLoginModalActive && (
        <LoginModal
          setLoginModalWindow={setLoginModalWindow} // This might be setLoginModal if using Auth context
          setValidLogin={setValidLogin} // This might be setValidLogin if using Auth context
          loginModalWindow={loginModalWindow} // You might use isLoginModalActive directly if using Auth context
          hideMenu={() => {}} // Implement this method if needed
          validLogin={validLogin} // This might be validLogin if using Auth context
          getUser={getUser} // This should retrieve and set the user's information after login
        />
      )}
    </div>
  );
};

export default EventDetailsPage;