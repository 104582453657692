import React from "react";
import { Link } from "react-router-dom";
import ResetLocation from '../../helpers/ResetLocation'
export default class ProgGridFotr extends React.Component {
  render() {
    const { blogPost } = this.props;
    return (
      <section className="aprog-grid-item">
        <img
          src={blogPost.img}
          alt={blogPost.name}
        />
        <section className="credentials">
          <p>{blogPost.date}</p>
         {/** <p>by {blogPost.author}</p> */}
        </section>
        
  <Link
    onClick={ResetLocation}
    to={`/progfotr/${blogPost.name.toLowerCase().replaceAll(' ', '-')}`}
  >
    <h3 className="black-text">{blogPost.name}</h3>
  </Link>


        <p className="bintro">{blogPost.intro}</p>
      </section>
    );
  }
}
