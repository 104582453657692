import React from "react";
import landingpg from '../../assets/images/hero/landingpg.png'

export default class AboutSectionTwo extends React.Component {
  render() {
    return (
      
      <article className="section-two">
        
        <section className="section-two-content">

        
          <h2 className="sub-title">Dedicated</h2>
          <h3>Changing the world together</h3>
          <p>
          At Carole Kinoti Brands, our essence revolves around the fusion of 
          vibrant Kenyan culture and innovative fashion designs. Rooted deeply 
          in our nation's rich tapestry, every piece curated in our collection 
          reflects the spirit, resilience, and beauty of Kenya. Beyond mere 
          aesthetics, our designs advocate for inclusivity, sustainability, and 
          empowerment. Working closely with local artisans, beaders, and creators,
           we ensure that each piece not only makes a style statement but also 
           tells a profound story of heritage and passion. Our brand's ethos goes 
           beyond just fashion — it's about building a movement. A movement where 
           every garment carries a legacy, and each thread weaves the future of 
           sustainable Kenyan fashion. Here, at Carole Kinoti Brands, you're not 
           just donning a piece of clothing; you're embracing an ethos, a culture, 
           a legacy.
          </p>
        </section>
        <div className="black-glass"></div>
      </article>
      
    );
  }
}
