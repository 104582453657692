import React, { useState, useEffect } from "react";
import { initializeApp, getApps } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore"; // Import query and where
import { app as firebaseConfig } from "../../firebaseConfig";
import Sidebar from "./sideBAr/Sidebar";
import { useNavigate } from "react-router-dom";

let firebaseApp;

if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
} else {
    firebaseApp = getApps()[0];
}

const db = getFirestore(firebaseApp);

const convertToSlug = (text) => {
    return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
        .trim();
};

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(""); // New state variable
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            let q = collection(db, "blogPosts");
            if (selectedCategory) {
                q = query(q, where("category", "==", selectedCategory));
            }
            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setPosts(data);
        };

        fetchData();
    }, [selectedCategory]);

    return (
        <div className="blogContainer">
            <Sidebar onCategoryClick={setSelectedCategory} />
            <div className="postsContainer">
                <div className="postsRow">
                    {posts.slice(0, Math.ceil(posts.length/2)).map((post) => (
                        <CardComponent key={post.id} post={post} onSelect={(postTitleSlug) => navigate(`/blog/${postTitleSlug}`)} />
                    ))}
                </div>
                <div className="postsRow">
                    {posts.slice(Math.ceil(posts.length/2)).map((post) => (
                        <CardComponent key={post.id} post={post} onSelect={(postTitleSlug) => navigate(`/blog/${postTitleSlug}`)} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const CardComponent = ({ post, onSelect }) => {
    return (
        <div className="postCard" onClick={() => onSelect(convertToSlug(post.name))}>
            <img src={post.img} alt={post.name} />
            <p>{post.date}</p>
            <h4>{post.name}</h4>
            <span>{post.category}</span>
        </div>
    );
};

export default Blog;
