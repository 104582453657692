import React from 'react'
//Images
import Visa from '../../assets/images/payment-methods/visa-icon.svg'
import MasterCard from '../../assets/images/payment-methods/mastercard-icon.svg'
import ApplePay from '../../assets/images/payment-methods/DHL-Emblem.png'
import GooglePay from '../../assets/images/payment-methods/googlepay-icon.svg'

export default class FooterContact extends React.Component {
  render() {
    return (
      <section className="footer-contact  flex-container flex-column">
        <h3>Ask Carole</h3>
        <p>+254 792769097</p>
        <p>enquiries@carolekinoti.co.ke</p>
        <p>8:00am - 5:00pm</p>
        <section className="accepted-payments">
          <img width="50" height="50" src={Visa} alt="Visa card" />
          <img width="50" height="50" src={MasterCard} alt="Mastercard card" />
          <img width="50" height="50" src={ApplePay} alt="Apple pay" />
          
        </section>
      </section>
    )
  }
}
