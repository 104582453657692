import React from "react";
import { motion } from "framer-motion";
import L from "leaflet";
import icon from "../../assets/images/maps/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, TileLayer, Marker, Tooltip  } from "react-leaflet";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const cities = [
  { 
    name: "Nairobi", 
    position: [-1.2921, 36.8219],
    category: "Brand Impact",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
  },
  { 
    name: "Mombasa", 
    position: [-4.0435, 39.6682],
    category: "Brand Impact",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
  },
  { 
    name: "Kisumu", 
    position: [-0.0917, 34.7674],
    category: "Brand Impact",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
  },
  { 
    name: "Kilifi", 
    position: [-3.6305, 39.84991],
    category: "Community Outreach",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
  },
  { 
    name: "Mombasa (Baus Taka)", 
    position: [-4.0547, 39.66362],
    category: "Community Outreach",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
  },
  { 
    name: "Makueni", 
    position: [-1.8041, 37.62033],
    category: "Community Outreach",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
  },
  { 
    name: "Kitui (Girls from Prada)", 
    position: [-1.375081, 37.9952134],
    category: "Community Outreach",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"
  },
  { 
    name: "Kisumu Golf Club", 
    position: [-0.08426, 34.746425],
    category: "Sport Series Partnerships",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
  },
  { 
    name: "Muthaiga Golf Club", 
    position: [1.2591, 36.8371],
    category: "Sport Series Partnerships",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
  },
  { 
    name: "Limuru Country Club", 
    position: [-1.1304796, 36.6704178],
    category: "Sport Series Partnerships",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
  },
  { 
    name: "Karen Country Club", 
    position: [-1.3395096, 36.7124285],
    category: "Sport Series Partnerships",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
  },
  { 
    name: "Royal Nairobi Golf Club", 
    position: [-1.3012616, 36.7947481],
    category: "Sport Series Partnerships",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png"
  },
  { 
    name: "Meru Prison", 
    position: [0.0078479, 37.5631672],
    category: "Brand Impact",
    icon: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
  }
];



const redIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const blueIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const yellowIcon = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const ContactLanding = () => {
  return (
    <article className="section-10  flex-container flex-column">
      <motion.div
        className="map"
        initial={{ opacity: 0, translateX: -300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: -300 }}
        transition={{ duration: 2 }}
      >
        <MapContainer
  id="map"
  center={[-1.2921, 36.8219]}
  zoom={6}
  scrollWheelZoom={false}
  zoomControl={false}  // Disable the default zoom controls
>
  <TileLayer
    attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <div className="map-title">Our Reach</div>

          {cities.map((city, index) => (
  <Marker
    key={index}
    position={city.position}
    icon={
      city.name.includes('Club') ? yellowIcon : 
      city.name === 'Nairobi' || city.name === 'Mombasa' || city.name === 'Kisumu' || 
      city.name === 'Meru Prison' || city.name === 'Madonna Community Resource Centre' || 
      city.name === 'Kodiaga Prison, Kisumu City' || city.name === 'Kajiado' ? blueIcon : redIcon
    }
  >
    {/* Add the Tooltip here */}
    <Tooltip zIndexOffset={1000}>
      {`${city.name} - ${city.category}`}
    </Tooltip>
  </Marker>
))}





<div className="map-legend">
  <div><img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png" alt="Blue Icon" />Brand Impact</div>
  <div><img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png" alt="Red Icon" /> Community Outreach</div>
  <div><img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png" alt="Yellow Icon" /> Sport Series Partnerships</div>
</div>
        </MapContainer>
      </motion.div>
      <motion.div
        className="contact-emails"
        initial={{ opacity: 0, translateX: 300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 300 }}
        transition={{ duration: 2 }}
      >
        <h3>Contact Us</h3>
        <p>Have a question, suggestion, or just want to say hello? We'd love to hear from you!</p>
        <section>
          <h4>Customer Support:</h4>
          <p>Our dedicated customer support team is ready to assist you with any inquiries you may have.</p>
          <ul>
            <li>- Email: enquiries@carolekinoti.co.ke</li>
            <li>- Phone: +254 792769097</li>
            <li>- Hours: Monday - Saturday, 8:00am - 5:00pm (EST)</li>
          </ul>
        </section>
        <section>
          <h4>General Inquiries:</h4>
          <p>If you have general questions about our company, collections, partnerships, or anything else, feel free to get in touch.</p>
          <ul>
            <li>- Email: enquiries@carolekinoti.co.ke</li>
          </ul>
        </section>
        <section>
          <h4>Feedback and Suggestions:</h4>
          <p>We value your feedback and are always striving to improve. If you have any suggestions, comments, or ideas on how we can enhance our services, please let us know.</p>
          <ul>
            <li>- Email: enquiries@carolekinoti.co.ke</li>
          </ul>

          

        </section>
      </motion.div>
    </article>
  );
}


export default ContactLanding;
