import React, { useEffect, useState } from "react";
import validateForm from "../../components/validateForm";
import { v4 as uuidv4 } from 'uuid';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig"; // Update the path to where you've initialized Firebase
import ResetLocation from "../../helpers/ResetLocation";
const Register = ({ activateLoginModal }) => {

  const [formValue, setFormValue] = useState({ id: '', email: '', password: '', repeatPassword: '', fullname: '', address: '', number: '' });
  const [formError, setFormError] = useState({})
  const [submit, setSubmit] = useState(false);
  const [registrationFail, setRegistrationFail] = useState(false);
  const [loading, setLoading] = useState(false);

    // Add a new state for the optional role
    const [role, setRole] = useState('');
    
  const getUsers = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_USERS_URL);
      const body = await response.json();
      return body.data;
    }
    catch (err) {
      console.log(err.message)
    }
  }

  const createUser = async (user) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, user.email, user.password);
      console.log(userCredential.user); // This will contain user information, including a UID given by Firebase
      return true;
    } catch (error) {
      console.error('Error creating a new user:', error);
      return false;
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const validationErrors = validate(formValue);
    setFormError(validationErrors);
    window.scrollTo(0, 0);
    
    if (Object.keys(validationErrors).length > 0) {
        setLoading(false);
        return;
    } else {
        let currForm = { ...formValue };
        if (currForm.repeatPassword.length > 0) {
            delete currForm.repeatPassword;
        }
        if (currForm.address !== undefined) {
            delete currForm.address;
        }
        if (currForm.number !== undefined) {
            delete currForm.number;
        }
        // Add role if it has been selected
        if (role) {
            currForm.role = role;
        }
        currForm.email = currForm.email.toLowerCase();
        const accCreation = await createUser(currForm);
        if (accCreation === false) {
            setLoading(false);
            setSubmit(false);
            setRegistrationFail(true);
            setFormValue({ id: '', email: '', password: '', repeatPassword: '', fullname: '', address: '', number: '' })
        } else {
            setLoading(false);
            setRegistrationFail(false);
            setSubmit(true);
            setFormValue({ id: '', email: '', password: '', repeatPassword: '', fullname: '', address: '', number: '' })
            // Reset role to empty after successful registration
            setRole('');
        }
    }
};

  const handleValidation = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value })
  }
  const validate = validateForm("registration");

  useEffect(() => {
    document.title = "Registration | Pizza Time";
  }, []);
  return (
    <main className="register-main">
      <h2>{submit && Object.keys(formError).length === 0 ? 'Success!' : 'Registration'}</h2>
      {loading ? <div role="status" className="loader">
        <p>Almost there...</p>
        <img alt="Processing request" src="https://media0.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif?cid=ecf05e472hf2wk1f2jou3s5fcnx1vek6ggnfcvhsjbeh7v5u&ep=v1_stickers_search&rid=giphy.gif&ct=s" />
      </div> : submit && Object.keys(formError).length === 0 ?
        <section className="registration-success">
          <p className="form-submit-msg">You can now log in and make an order!</p>
          <button
            className="passive-button-style txt-white"
            onClick={() => {
              ResetLocation()
              activateLoginModal()
              setSubmit(false);
            }}
          >
            Log in
          </button>
        </section>

        :
        <form className="registration-form" onSubmit={handleSubmit}>
          {registrationFail ? <p className="registration-input-err">Seems like this email has already been registered!</p> : null}
          <section className="name-section">
            <input type="text" placeholder="Full name" name="fullname" value={formValue.fullname}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.fullname}</span>
          </section>
          <section className="email-section">
            <input type="text" placeholder="Email" name="email" value={formValue.email}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.email}</span>
          </section>
          <section className="password-section">
            <input type="password" placeholder="New password" name="password" value={formValue.password}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.password}</span>
            <input type="password" placeholder="Repeat password" name="repeatPassword" value={formValue.repeatPassword}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.repeatPassword}</span>
          </section>
          <section className="role-section">
          <select class="form-select" name="role" value={role} onChange={handleValidation}>
            <option value="">Select your role (optional)</option>
            <option value="CEO">CEO</option>
            {/* Add more roles here if necessary */}
          </select>
        </section>
          <section className="birthday">
            <input type="text" placeholder="Address (optional)" name="address" value={formValue.address}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.address}</span>
          </section>
          <section className="birthday">
            <input type="text" placeholder="Number (optional)" name="number" value={formValue.number}
              onChange={handleValidation} />
            <span className="registration-input-err">{formError.number}</span>
          </section>
          <p className="terms-warning">
            By clicking Sign Up, you agree to our Terms, Data Policy and Cookies
            Policy. You may receive an email notification from us and can opt
            out any time.
          </p>
          <button className="register-btn" type="submit">Sign up</button>
        </form>
      }

    </main>
  );
}


export default Register;