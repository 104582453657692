import React from "react";
import { AllProgPostsFotr as allBlogPosts } from "../../data/AllProgPostsFotr";
import ReactPaginate from 'react-paginate';
import { useEffect, useState } from "react";
import ResetLocation from "../../helpers/ResetLocation";
import { motion } from "framer-motion";
import ProgGridFotr from "./ProgGridFotr";

const ProgFotr = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 10);
  const [currentBlogPosts, setcurrentBlogPosts] = useState([...allBlogPosts].reverse().slice(itemOffset, endOffset));
  const [pageCountPosts, setpageCountPosts] = useState(Math.ceil(allBlogPosts.length / 10));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % allBlogPosts.length;
    setItemOffset(newOffset);
    ResetLocation();
  };

  useEffect(() => {
    document.title = "Blog | Carole Kinoti Brand";
    setEndOffset(itemOffset + 10);
    setcurrentBlogPosts([...allBlogPosts].reverse().slice(itemOffset, endOffset));
    setpageCountPosts(Math.ceil(allBlogPosts.length / 10));

  }, [setEndOffset, endOffset, itemOffset]);
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <main className="aprog">
        <article className="aprog-section">
          <h2>Fashion On the Road Programs</h2>
          <p className="aprog-intro">
          Fashion on the Road (FOTR) Programs are a transformative journey that redefines fashion. Our mission is clear: engage, uplift, and inspire. We empower women, people with disabilities, and youth through Made In Kenya fashion. Each step of our journey breathes life into used clothes and textile waste, promotes cultural appreciation, and fosters inclusivity, sustainability, and empowerment. Join us, where fashion sparks meaningful change.
          </p>
          <section className="prog-grid">
            {currentBlogPosts.map((blogPost, index) => {
              return <ProgGridFotr key={index} blogPost={blogPost} />;
            })}
          </section>
        </article>
        <ReactPaginate
          className="pagination blog-pagination"
          breakLabel="..."
          nextLabel=" &#62;"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCountPosts}
          previousLabel="&#60;"
          renderOnZeroPageCount={null}
        />
        
      </main>
    </motion.div>
  );
}
export default ProgFotr;