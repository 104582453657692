export const AllCategories = [
  {
    name: "CKB Collections",
    id: "CKB Collections",
  },
  {
    name: "Blanketi",
    id: "Blanketi",
  },
  {
    name: "Hariri",
    id: "Hariri",
  },
  {
    
    name: "Dhahabu",
    id: "Dhahabu",
  },
  {
    name: "Afro Collection",
    id: "Afro Collection",
  },
  {
    name: "Athleisure",
    id: "Athleisure",
  },
  {
    name: "LAD Collection",
    id: "LAD Collection",
  },
];
