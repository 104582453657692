import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app as firebaseApp } from '../../firebaseConfig';
import { Card, CardActionArea, CardContent, CardMedia, Button, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';

const db = getFirestore(firebaseApp);

const DESIRED_CARDS_PER_ROW = 4;

const EventsPage = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEventsData = async () => {
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);

      let eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Adjust the array to ensure an even number of cards
      while (eventsData.length % DESIRED_CARDS_PER_ROW !== 0) {
        eventsData = [...eventsData, ...eventsData.slice(0, DESIRED_CARDS_PER_ROW - (eventsData.length % DESIRED_CARDS_PER_ROW))];
      }

      setEvents(eventsData);
    };

    fetchEventsData();
  }, []);

  const handleCardClick = (event) => {
    navigate(`/event/${event.id}`);
  };

  return (
    <div>
      <h1>Upcoming Events</h1>
      <div className="event-container">
        {events.map((event) => (
          <Card key={event.id} sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => handleCardClick(event)}>
            <div className="icons-container">
    <FavoriteBorderIcon className="event-icon"/>
    <ShareIcon className="event-icon"/>
  </div>
              <CardMedia
                component="img"
                height="450"
                image={event.imageUrl}
                alt={event.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {event.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {event.date} {event.time}
                </Typography>
                <Typography variant="body2" style={{ color: event.categoryId === 'FOTR' ? 'red' : 'inherit' }}>
                  {event.organizer}
                </Typography>
              </CardContent>
            </CardActionArea>
            
          
          </Card>
        ))}
      </div>
    </div>
  );
};

export default EventsPage;