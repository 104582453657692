import React from "react";
import { Facebook, LinkedIn, Instagram } from '@mui/icons-material';

const socials = [
  {
    id: 1,
    href: "https://www.facebook.com/carolekinoti/",
    Icon: Facebook,
    name: 'Facebook'
  },
  {
    id: 2,
    href: "https://www.linkedin.com/in/carole-kinoti-177501127/",
    Icon: LinkedIn,
    name: 'LinkedIn'
  },
  {
    id: 3,
    href: "https://www.instagram.com/carole_kinoti/",
    Icon: Instagram,
    name: 'Instagram'
  }
];

export default class FooterSocials extends React.Component {
  render() {
    return (
      <ul className="socials">
      {socials.map(social =>
      <li key={social.id}>
          <a
            href={social.href}
            target="_blank"
            rel="noreferrer"
            aria-label={social.name}
          >
          <span aria-hidden="true">{social.name}</span>
            <social.Icon style={{ color: 'white' }} />
          </a>
        </li>)}
      </ul>
    );
  }
}
