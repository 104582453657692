import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ResetLocation from '../../helpers/ResetLocation';
import {
  getFirestore,
  collection,
  query,
  getDocs,
} from 'firebase/firestore';
import { app } from '../../firebaseConfig'; // Import 'app' from firebaseConfig

const CollectionsPreview = () => {
  const [ckbCollectionsVisible, setCkbCollectionsVisible] = useState(true);
  const [fotrCollectionsVisible, setFotrCollectionsVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [collections, setCollections] = useState([]); // Store Firestore data

  // Firestore instance
  const db = getFirestore(app);

  // Effect to save scroll position when the component mounts
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Fetch data from Firestore collection 'ckbfotrCollections'
    const fetchCollections = async () => {
      const q = query(collection(db, 'ckbfotrCollections')); // Use the correct collection name

      try {
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCollections(data);
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchCollections();
  }, [db]);

  const toggleCkbCollections = () => {
    setCkbCollectionsVisible(!ckbCollectionsVisible);
  };

  const toggleFotrCollections = () => {
    setFotrCollectionsVisible(!fotrCollectionsVisible);
  };

  // Filter CKB and FOTR collections based on the ID
  const ckbCollections = collections.filter((collection) => collection.id >= 1 && collection.id <= 10);
  const fotrCollections = collections.filter((collection) => collection.id > 10);

  return (
    <article className="section-4 flex-container flex-column">
      <section className="section-4-info txt-center">
        <h2
          className={`pop-font txt-white clickable ${
            ckbCollectionsVisible ? 'active' : ''
          }`}
          onClick={toggleCkbCollections}
        >
          CKB Collections
        </h2>
        {ckbCollectionsVisible && (
          <div>
            <p className="section-description" style={{ color: 'white' }}>
              CKB Collections description
            </p>
            <section className="meals-grid flex-container flex-column">
              {ckbCollections.map((collection, id) => (
                <motion.div
                  key={id}
                  className="meal-item flex-container"
                  initial={{ opacity: 0, translateX: -300 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: -300 }}
                  transition={{ duration: 3 }}
                >
                  {/* Render CKB Collections data */}
                  <img
                    src={collection.img375}
                    srcSet={`${collection.img1440} 300w, ${collection.img700} 450w, ${collection.img375} 375w`}
                    sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
                    alt={collection.name}
                  />
                  <section className="meal-item-details flex-container flex-column">
                    <h3 className="txt-white">{collection.name}</h3>
                    <p>{collection.description}</p>
                    <section className="meal-item-order flex-container flex-row txt-center">
                      <p>
                        <span>{collection.currency}</span>
                        {collection.price}
                      </p>
                    </section>
                  </section>
                </motion.div>
              ))}
            </section>
            <div style={{ marginTop: '50px' }}>
              <Link
                onClick={ResetLocation}
                to="/menu"
                className="active-button-style txt-white"
              >
                More CKB Collections
              </Link>
            </div>
          </div>
        )}
      </section>
      <section className="section-4-info txt-center">
        <h2
          className={`pop-font txt-white clickable ${
            fotrCollectionsVisible ? 'active' : ''
          }`}
          onClick={toggleFotrCollections}
        >
          FOTR Collections
        </h2>
        {fotrCollectionsVisible && (
          <div>
            <p className="section-description" style={{ color: 'white' }}>
              FOTR Collections description
            </p>
            <section className="meals-grid flex-container flex-column">
              {fotrCollections.map((collection, id) => (
                <motion.div
                  key={id}
                  className="meal-item flex-container"
                  initial={{ opacity: 0, translateX: -300 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: -300 }}
                  transition={{ duration: 3 }}
                >
                  {/* Render FOTR Collections data */}
                  <img
                    src={collection.img375}
                    srcSet={`${collection.img1440} 300w, ${collection.img700} 450w, ${collection.img375} 375w`}
                    sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
                    alt={collection.name}
                  />
                  <section className="meal-item-details flex-container flex-column">
                    <h3 className="txt-white">{collection.name}</h3>
                    <p>{collection.description}</p>
                    <section className="meal-item-order flex-container flex-row txt-center">
                      <p>
                        <span>{collection.currency}</span>
                        {collection.price}
                      </p>
                    </section>
                  </section>
                </motion.div>
              ))}
            </section>
            <div style={{ marginTop: '50px' }}>
              <Link
                onClick={ResetLocation}
                to="/fotr"
                className="active-button-style txt-white"
              >
                More FOTR Collections
              </Link>
            </div>
          </div>
        )}
      </section>
    </article>
  );
};

export default CollectionsPreview;
