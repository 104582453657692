import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Ensure this path is correct

// Import ReactQuill and the CSS for styling
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogPost = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [activeSection, setActiveSection] = useState('background');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { name } = useParams();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchBlogPostByName = async () => {
      try {
        const q = query(collection(db, "Carole-programs"), where("slug", "==", name));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const fetchedBlogPost = querySnapshot.docs[0].data();
          setBlogPost(fetchedBlogPost);
        } else {
          console.log('No such document with name:', name);
          navigate('/404');
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchBlogPostByName();
  }, [name, navigate]);

  useEffect(() => {
    if (blogPost) {
      document.title = `${blogPost.name} | Carole Kinoti Brands`;
    }
  }, [blogPost]);

  const handleApplicationClick = () => {
    if (blogPost?.applicationLink) {
      window.open(blogPost.applicationLink, "_blank");
    }
  };

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    <main className="blog-post">
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <span className="hamburger-icon">&#9776;</span> {/* Hamburger icon */}
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button onClick={() => setActiveSection("background")}
                className={`sidebar-button ${activeSection === "background" ? "active" : ""}`}>
          Background
        </button>
        <button onClick={() => setActiveSection("goal")}
                className={`sidebar-button ${activeSection === "goal" ? "active" : ""}`}>
          Goal
        </button>
        <button onClick={() => setActiveSection("admission")}
                className={`sidebar-button ${activeSection === "admission" ? "active" : ""}`}>
          Admission
        </button>
        {blogPost?.applicationLink && (
          <button onClick={handleApplicationClick} className="sidebar-button">
            Application
          </button>
        )}
      </div>
      <div className="content">
        <section className="blog-content">
          <h1>{blogPost?.name}</h1>
          <img src={blogPost?.img} alt={blogPost?.name} aria-hidden="true" />
          
          {activeSection === "background" && (
            <section className="blog-content-section">
              <h2>Background</h2>
              <ReactQuill value={blogPost.background || ''} readOnly={true} theme={null} />
            </section>
          )}
  
          {activeSection === "goal" && (
            <section className="blog-content-section">
              <h2>Goal</h2>
              <ReactQuill value={blogPost.goal || ''} readOnly={true} theme={null} />
            </section>
          )}
  
          {activeSection === "admission" && (
            <section className="blog-content-section">
              <h2>Admission</h2>
              <ReactQuill value={blogPost.admission || ''} readOnly={true} theme={null} />
            </section>
          )}
        </section>
      </div>
    </main>
  );
  
};

export default BlogPost;
