import React, { useState, useEffect } from 'react';
import { initializeApp, getApps } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { app as firebaseConfig } from "../../../firebaseConfig";
import './sidebar.css'

// Initialize Firebase if it hasn't been already
if (!getApps().length) {
    initializeApp(firebaseConfig);
}

const db = getFirestore();

const Sidebar = ({ onCategoryClick }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [blogCategories, setBlogCategories] = useState([]);

    const handleCategoryClick = async (categoryTitle) => {
        // Notify the parent component (Blog) about the category click
        onCategoryClick(categoryTitle);

        // Collapse the sidebar
        setCollapsed(true);
    };

    useEffect(() => {
        async function fetchData() {
            const querySnapshot = await getDocs(collection(db, "blogCategory"));
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), id: doc.id });
            });
            setBlogCategories(data);
        }

        fetchData();
    }, []);

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : 'expanded'}`}>
            {collapsed ? (
                <div className="collapsed-content" onClick={() => setCollapsed(false)}>
                    <span>+</span>
                    <p>VIEW ALL</p>
                </div>
            ) : (
                <div className="expanded-content">
                    <span onClick={() => setCollapsed(true)}>x</span>
                    {blogCategories.map(category => (
                        <div key={category.id} className="category" onClick={() => handleCategoryClick(category.title)}>
                            <img src={category.img} alt={category.title} />
                            <p>{category.title}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Sidebar;
