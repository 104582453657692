import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddToCartButton from '../cart/AddToCartButton';
import EAttribute from './Eattribute'; // Make sure to import the correct component
import ResetLocation from "../../helpers/ResetLocation";
import "./emenu.css"; // Import the specific CSS file

const EmenuGridItem = ({ singleEfotrProduct, handleAddEfotrProduct, handleRemoveProduct }) => {
  const [selectedEfotrAttributes, setselectedEfotrAttributes] = useState([]);
  const [targetAttribute, setTargetAttribute] = useState('');

  const handleselectedEfotrAttributes = (attributeId, attributeValue) => {
    setTargetAttribute(attributeValue);
    const newSelectedAttribute = { attributeId, attributeValue };
    setselectedEfotrAttributes(prevAttributes => {
      const existingAttributeIndex = prevAttributes.findIndex(
        attribute => attribute.attributeId === newSelectedAttribute.attributeId
      );
      if (existingAttributeIndex !== -1) {
        const updatedAttributes = [...prevAttributes];
        updatedAttributes[existingAttributeIndex] = { ...newSelectedAttribute };
        return updatedAttributes;
      } else {
        return [...prevAttributes, newSelectedAttribute];
      }
    });
  };

  return (
    <article className="menu-grid-item flex-container flex-column txt-white">
      <Link onClick={ResetLocation} to={`/efotr/${singleEfotrProduct.id}`} className="menu-item-link">
        <img src={singleEfotrProduct.ItemImg} alt={`${singleEfotrProduct.ItemName}`} />
      </Link>
      <h3>{singleEfotrProduct.ItemName}</h3>
      <p>{singleEfotrProduct.ItemIngredients}</p>
      {singleEfotrProduct.attributes.length === 0 ? null :
        singleEfotrProduct.attributes?.map(attribute => (
          <EAttribute
            key={attribute.id}
            className="attributes"
            handleSelectedEfotrAttributes={handleselectedEfotrAttributes}
            attribute={attribute}
            targetAttribute={targetAttribute}
          />
        ))
      }
      <div className="price">
        {singleEfotrProduct.sale === true ?
          <section className="sale-pricing">
            <p className="price-num-before"><span>$</span>{singleEfotrProduct.ItemPriceBefore}</p>
            <p className="price-num"><span>$</span>{singleEfotrProduct.ItemPrice}</p>
          </section>
          :
          <p className="price-num"><span>$</span>{singleEfotrProduct.ItemPrice}</p>
        }
        <AddToCartButton
          handleAddEfotrProduct={handleAddEfotrProduct}
          handleRemoveProduct={handleRemoveProduct}
          singleEfotrProduct={singleEfotrProduct}
          selectedEfotrAttributes={selectedEfotrAttributes}
          targetAttribute={targetAttribute}
          setTargetAttribute={setTargetAttribute}
        />
      </div>
    </article>
  );
};

export default EmenuGridItem;
