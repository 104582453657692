// BlogGrid.js
import React from "react";
import { Link } from "react-router-dom";
import ResetLocation from '../../helpers/ResetLocation';

export default class BlogGrid extends React.Component {
  render() {
    const { blogPost } = this.props;
    
    // Check if blogPost and blogPost.name exist
    if (!blogPost || !blogPost.name) {
      console.error('Blog post or blog post name is undefined', blogPost);
      return null; // Or a placeholder component
    }
    
    return (
      <section className="prog-grid-item">
        <img
          src={blogPost.img}
          alt={blogPost.name}
        />
        <section className="credentials">
          <p>{blogPost.date}</p>
          {/* <p>by {blogPost.author}</p> */}
        </section>
        <Link
          onClick={ResetLocation}
          to={`/prog/${blogPost.name.toLowerCase().replaceAll(' ', '-')}`}
        >
          <h3 className="black-text">{blogPost.name}</h3>
        </Link>
        <p className="bintro">{blogPost.intro}</p>
      </section>
    );
  }
}